<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
      height="auto"
      @click:outside="cancel()"
      @keydown.esc="cancel()"
    >
      <v-card>
        <v-card-text class="pa-16 mt-n6">
          <v-row>
            <v-col cols="12" class="text-center">
              <v-img
                width="160px"
                src="../../assets/logo/Logo1.jpg"
                alt="logoDragon"
                class="mx-auto"
                style="max-width: 100%"
              />
            </v-col>
            <!-- <v-col cols="12">
              <v-text-field
                v-model="form.telNumber"
                :placeholder="$t('message.telnumber')"
                dense
                outlined
                hide-details="auto"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.password"
                :type="showPassword ? '' : 'password'"
                :placeholder="$t('message.password')"
                dense
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col> -->

            <!-- <v-row class="mt-0" no-gutters justify="center" v-if="!OTPpass">
              <v-col cols="12" md="12" sm="12" class="text-center">
                <h2 style="font-weight: 400">
                  <span style="color: #4ca3ad"><u>เข้าสู่ระบบ</u></span>
                </h2>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="text-center">
                <br />
                <h3 style="font-weight: 200">กรอกเบอร์มือถือ</h3>
              </v-col>

              <span>ระบบจะส่งรหัส OTP ให้ทาง SMS เพื่อยืนยัน</span>
              <v-col cols="12" md="12" sm="12" class="mt-4">
                <v-text-field
                  v-model="form.telNumber"
                  placeholder="กรอกเบอร์มือถือ 10 หลัก"
                  solo
                  background-color="white"
                  dense
                  @keyup.enter="OTP()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="text-center mt-n2">
                <v-btn style="background: #008080" @click="OTP()"
                  ><span style="color: white">ขอรหัส</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mt-n4" no-gutters justify="center" v-if="OTPpass">
              <v-col cols="12" md="12" sm="12" class="text-center">
                <h2 style="font-weight: 400">ยืนยันรหัส OTP</h2>
              </v-col>

              <span>กรอกรหัส OTP ที่ได้รับจากโทรศัพท์มือถือ</span>
              <v-col cols="8" md="8" sm="8">
                <v-text-field
                  v-model="otp_code"
                  solo
                  background-color="white"
                  dense
                  @keyup.enter="verifyOTP()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="mb-2 mt-n4 text-center">
                <v-btn style="background: #008080" @click="verifyOTP()"
                  ><span style="color: white">ยืนยันหมายเลข OTP</span>
                </v-btn>
              </v-col>
              <v-col cols="12" md="6" sm="12" class="mb-2 text-center">
                <v-btn
                  text
                  style="border: solid 1px; border-color: #4ca3ad"
                  @click="OTP()"
                  >ขอรหัส OTP ใหม่อีกครั้ง</v-btn
                >
              </v-col>
            </v-row> -->
            <!-- <v-col cols="12" class="d-flex justify-center mt-n6">
              <v-checkbox
                v-model="showPassword"
                :label="$t('message.showpassword')"
              ></v-checkbox>
            </v-col> -->
            <!-- <v-col cols="12" class="d-flex justify-end mt-n8">
              <a
                ><u>{{ $t("message.forgotpassword") }}</u></a
              >
            </v-col> -->
            <!-- <v-col cols="12" class="d-flex justify-center">
              <v-btn
                class="custom-btn"
                @click="login()"
                style="background-color: #66cdaa"
              >
                <span style="font-size: 16px; font-weight: bold">{{
                  $t("message.login")
                }}</span></v-btn
              >
            </v-col> -->
            <v-col cols="12" class="d-flex justify-center mt-6">
              <v-btn
                class="custom-btn"
                style="background-color: #07bc0c"
                @click="goToLineLogin()"
              >
                <span style="font-size: 16px; font-weight: bold; color: white"
                  >{{ $t("message.login") }} LINE
                </span>
                &emsp;
                <v-img
                  src="../../assets/logo/Line-Logo.png"
                  max-width="20px"
                  height="auto"
                ></v-img
              ></v-btn>
            </v-col>
            <v-col cols="12" class="d-flex justify-center mt-4">
              <span>{{ $t("message.notmember") }}</span>
            </v-col>
            <v-col cols="12" class="d-flex justify-center mt-n4">
              <v-btn
                class="custom-btn"
                style="
                  background-color: #fff;
                  border: 2px solid;
                  border-color: #66cdaa;
                "
                @click="goToLineLogin()"
              >
                <span
                  style="font-size: 16px; font-weight: bold; color: #66cdaa"
                  >{{ $t("message.registernow") }}</span
                ></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Encode, Decode } from "@/services";
export default {
  props: {
    dialog: Boolean,
  },

  data() {
    return {
      showPassword: false,
      form: {},
      OTPpass: false,

      api_key: "d551e09b93553c9970e9634a5046cd5a",
      secret_key: "i0rdbEDaUdqyBo3r",
      project_key: "1b835546c3",
      otp_code: "",
      token: "",
    };
  },

  methods: {
    goToLineLogin() {
      const lineLoginChannelId = "2003677642";
      const redirectUrl = `https://mewniyomlottery.com/lineLogin`;
      const scope = "profile%20openid%20email";
      const state = "12w3xt3x";
      const lineLoginUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${lineLoginChannelId}&redirect_uri=${redirectUrl}&scope=${scope}&state=${state}`;
      window.location.href = lineLoginUrl;
    },
    goToRegister() {
      this.$router.push("register");
    },
    OTPtest() {
      this.OTPpass = true;
    },
    maxLengthRule() {
      const sanitizedValue =
        this.form.telNumber && this.form.telNumber.replace(/[^0-9]/g, "");
      return (
        (sanitizedValue && sanitizedValue.length <= 10) ||
        this.$t("message.digit10length")
      );
    },
    cancel() {
      this.$emit("closeDialogLogin");
    },
    async OTP() {
      const auth = {
        headers: {
          "Content-Type": "application/json",
          api_key: this.api_key,
          secret_key: this.secret_key,
        },
      };
      const params = {
        project_key: this.project_key,
        phone: this.form.telNumber,
      };
      const response = await this.axios.post(
        `https://portal-otp.smsmkt.com/api/otp-send`,
        params,
        auth
      );

      this.token = response.data.result.token;

      if (response.data.code === "000") {
        this.OTPpass = true;
      } else if (this.telNumber == "") {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          icon: "error",
          text: "กรุณากรอกหมายเลขโทรศัพท์",
        });
      } else {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "ระบบ OTPขัดข้อง",
        });
      }
    },
    async verifyOTP() {
      const auth = {
        headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          api_key: this.api_key,
          secret_key: this.secret_key,
          // "Access-Control-Allow-Origin": "*"
        },
      };
      const params = {
        token: this.token,
        otp_code: this.otp_code,
      };

      const response = await this.axios.post(
        `https://portal-otp.smsmkt.com/api/otp-validate`,
        params,
        auth
      );

      if (response.data.code == "5000") {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "รหัส OTP ของท่านหมดอายุ กรุณาขอ OTP ใหม่",
        });
      } else {
        if (response.data.result.status === true) {
          this.login();
        } else {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "error",
            text: "รหัส OTP ของท่านผิด กรุณากรอกใหม่อีกครั้ง",
          });
        }
      }
    },

    async login() {
      const login = {
        telNumber: this.form.telNumber,
        password: this.form.password,
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/users/login`,
        login
      );

      if (response.data.response_status === "SUCCESS") {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          icon: "success",
          text: "เข้าสู่ระบบเสร็จสิ้น",
        });
        localStorage.setItem("MEWUser", Encode.encode(response.data.data));
        this.$router.currentRoute.path == "/"
          ? this.$router.go(0)
          : this.$router.push("/").then(() => {
              this.$router.go(0);
            });
      } else if (response.data.response_status === "ERROR") {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          icon: "error",
          text: response.data.message,
        });
      }
    },
  },
};
</script>

<style scoped>
.custom-btn {
  min-width: 250px !important;
}
</style>