<template>
  <v-app
    class="gradient-background"
    style="width: 100%; height: 100%"
    :style="{ '--font-family': $i18n.locale === 'th' ? 'Kanit' : 'Poppins' }"
  >
    <div style="margin-top: 80px">
      <Appbar />
    </div>
    <router-view />
    <div style="margin-top: 200px">
      <sFooter />
    </div>
  </v-app>
</template>

<script>
import Appbar from "./components/Appbar.vue";
import sFooter from "./components/Footer.vue";

export default {
  components: {
    Appbar,
    sFooter,
  },

  methods: {},
};
</script>
<style>
@import "./assets/styles.css";
/* html {
  font-family: "Kanit" !important;
} */

.th-font {
  font-family: "Kanit" !important;
}

.en-font {
  font-family: "OtherFont" !important;
}
.sticky {
  position: fixed;
  top: 6%;
  width: 100%;
  z-index: 1;
}

.Appbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

/* Styles for router-view */
router-view {
  margin-top: 80px; /* Adjust as needed */
}

/* Styles for sFooter */
sFooter {
  margin-top: 200px; /* Adjust as needed */
}
</style>


