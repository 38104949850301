import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import i18nTH from "./assets/i18n/th.json";
import i18nEN from "./assets/i18n/en.json";
import VueI18n from "vue-i18n";
import ToggleButton from "vue-js-toggle-button";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import axios from "axios";
import VueAxios from "vue-axios";
import VueQRCodeComponent from "vue-qr-generator";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "th",
  fallbackLocale: "th",
  messages: {
    th: i18nTH,
    en: i18nEN,
  },
});

Vue.config.productionTip = false;

Vue.use(ToggleButton);
Vue.use(VueSweetalert2);
Vue.use(VueAxios, axios);
Vue.component("qr-code", VueQRCodeComponent);

new Vue({
  router,
  vuetify,
  render: function (h) {
    return h(App);
  },
  i18n,
}).$mount("#app");
